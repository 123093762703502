import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import BlogCard from "../components/blogCard";
import Hero from "../components/hero";
import Layout from "../components/layout";

const BlogPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			logoImg: wpMediaItem(title: { eq: "cyberEssentials-1 1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpPost(
				sort: { fields: dateGmt, order: DESC }
				filter: { title: { ne: "Website Images" } }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt(formatString: "D MMMM yyyy")
					blogFields {
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										width: 1920
										formats: [WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Blog | Cranfield IT Solutions"
				description="Stay updated with our cutting-edge tech insights and troubleshooting tips through our latest blog articles, designed to empower you with comprehensive IT support knowledge."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/blog`,
					title: "Blog | Cranfield IT Solutions",
					description:
						"Stay updated with our cutting-edge tech insights and troubleshooting tips through our latest blog articles, designed to empower you with comprehensive IT support knowledge.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<Hero title={<>Blog</>} url="/contact-us" />

				<section className="pb-5 pb-lg-7">
					<Container>
						<Row>
							<Col lg={8}>
								<h2 className="fs-1 mb-4 ">Our latest blog posts</h2>
								<p className="pb-md-3">
									Stay updated with our cutting-edge tech insights and
									troubleshooting tips through our latest blog articles,
									designed to empower you with comprehensive IT support
									knowledge.
								</p>
							</Col>
						</Row>
						<Row className="g-5 g-xl-6">
							{data.allWpPost.nodes.map((blog, index) => (
								<BlogCard
									img={
										blog.blogFields.featuredImage.localFile.childImageSharp
											.gatsbyImageData
									}
									imgAlt={blog.blogFields.featuredImage.altText}
									title={blog.title}
									date={blog.dateGmt}
									url={`/blog/${blog.slug}`}
								/>
							))}
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default BlogPage;
